#PDF_CONTAINER{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

#PDFVIEWER{
    width: 100%;
    min-height: 500px;
    height: 100vw;
    max-height: 650px;
}