.sticky{
    position: fixed;
    z-index: 10;
    top: 0;
}

.nav-card{
    height: 5.5rem;
}

.alert-sticky{
    position: fixed;
    z-index: 10;
    top: 100px;
}

.header-container{
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-content: baseline;
    justify-content: space-between;
}

.header-button-container{
    position: absolute;
    right: 0;
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.header-alert{
    z-index: 12;
}

.proposal-input{
    width: 40%;
    border: none;
    height: 4rem;
}

.proposal-input:focus{
    border: none;
}