.landing-page-jumbo{
    background-color: white;
    border-radius: 10px;
    padding-bottom: 0;
}

.landing-page-title{
    font-size: 6vw;
}

.qouteix-logo{
    max-width: 120px;
    width: 10vw;
    min-width: 70px;
}

.landing-page-title-container{
    text-align: center;
    font-size: 1rem;
}

.landing-page-top-image-container{
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: center;
    padding-top: 30%;
}

.landing-page-top-image{
    width: 60%;
}


.landing-page-title-container > p{
    font-size: 1.3rem;
}   

.landing-page-desc-header{
    text-align: center;
    margin: 2%;
    font-size: 1.7rem;
}

.landing-page-desc-row{
    height: 100px !important;
}

.landing-page-desc{
    margin: 2%;
    padding-top: 2%;
    font-size: 1.3rem;
}


.landing-page-bottom-button{
    max-width: 300px;
    width: 50%;
    margin-bottom: 7%;
    margin-left: auto;
    margin-right: auto;
    
}

.landing-page-bottom-button-container{
    text-align: center;
}

@media only screen and (max-width: 900px) {
    .landing-page-top-column{
        display: none;
    }

    .landing-page-top-image{
        display: none;
    }
}