.header-container{
    position: relative;
    width: 100%;
}

@media only screen and (min-width: 650px) {
    .header-button-containers{
        position: absolute;
        right: 0;
        width: 55%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
