.business-card-box{
    position: relative;
}

.business-card{
    width: 100%;
}

.business-card-delete-button{
    position: absolute;
    right: 3%;
    top: 0;
    border-radius: 100%;
    border: none;
    margin: 0;
    padding: 0;
    font-size: 1.3rem;
    background: transparent;
    color: red
}

.save-button-column{
    position: relative;
}

.save-button-column > .business-profile-header-alert {
    position: absolute;
    right: 3%;
    top: 0;
}

.business-card-submit{
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}