.pdf-viewer-modal-header{
    position: relative;
}

.pdf-viewer-modal-buttons{
    position: absolute;
    top: 0;
    right: 1%;
    width: 50%;
    text-align: right;
}

.pdf-viewer-modal-buttons > .btn{
    margin-left: 3%;
}