.areasAndItems{
    overflow-y: auto;
}

.image-container{
    position: relative;
    z-index: 5;
    width: 150px;
    height: 150px;
    display: flex;
    align-items: center;
    min-width: 100px;
    padding: 0;
    margin: 0;
}

.item-image{
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: 150px;
    max-height: 150px;
}

.change-image-label{
    position: absolute;
    width: 150px;
    height: 100%;
    font-size: 3rem;
    background-color: rgba(128, 128, 128, 0.226);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

td{
    margin: 0 !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  
}

.name{
   max-width: 200px;
}


.qty{
    width: 5vw;
    max-width: 80px;
}

.price{
    margin: 0;
    width: 10vw;
    max-width: 100px;
}

.desc{
    max-width: 300px !important;
    line-height: 1.2 !important;
    height: 150px !important;
    width: 22vw;
}

.item-remove-button{
    font-size: 10px;
    padding: 8px;
}

.item-remove-button:hover{
    background-color: red !important;
}

.change-image-input{
    opacity: 0;
    width: 150px;
    height: 100%;
    position: absolute;
    top: 0;
}

.change-image-input:hover{
    cursor: pointer;
}

.no-image{
    width: 100%;
    height: 100%;
    font-size: 0.8rem;
    background-color: lightgray
}

.header-and-remove-button{
    position: relative;
}

.area-remove-button:hover{
    background-color: red !important;
}

.item-check-button{
    font-size: 100px;
}

.item-check-button-label{
    max-width: 100px;
    width: 5vw;
    position: relative;
    font-weight: bolder;
    right: 5%;
}

.header-and-remove-button > .btn {
    position: absolute;
    right: 0;
    top: 0;
}

.area-name{
    width: 20%;
    font-size: 2rem;
}

